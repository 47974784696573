.container {
  --_theme-color: var(--page-theme-contrast-color, var(--color-text));
  --_theme-background-color: var(--page-theme-background-color);

  background-color: var(--page-theme-background-color, transparent);
}

.stage {
  margin-top: 100px;
  padding-inline: var(--spacing-sides);

  @media (width >= 650px) {
    margin-top: 0; /* why does this go back to 0? */
  }

  @media (width >= 1000px) {
    margin-block: 100px;
  }
}

.stage-inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.stage-main {
  position: relative; /* dietary mark */

  @media (width >= 650px) {
    display: grid;
    align-items: center;
    grid-column-gap: 20px;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
    height: 971px;
  }

  @media (width >= 1000px) {
    background-color: transparent;
    height: 765px;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 58px;
  --high-sugar-mark-inset-inline-end: 20px;
  --high-sugar-mark-width: 45px;

  @media (width >= 650px) {
    --high-sugar-mark-inset-block-start: 100px;
    --high-sugar-mark-width: 55px;
  }
}

.text {
  position: relative;
  z-index: 1;

  @media (width >= 650px) {
    grid-column-start: 4;
    grid-column-end: -1;
    order: 2;
  }

  @media (width >= 1000px) {
    grid-column: 6 / -2;
    margin-bottom: 75px;
  }
}

.can {
  position: relative;
  z-index: 1;
  margin-inline: auto;
  width: 132px;

  @media (width >= 650px) {
    grid-column-start: 1;
    grid-column-end: 4;
    order: 1;
    margin-inline: initial;
    width: 152px;
  }

  @media (width >= 1000px) {
    grid-column-start: 2;
    grid-column-end: 4;
    width: 210px;
  }
}

.can--stamp {
  width: 200px;

  @media (width >= 650px) {
    width: 230px;
  }

  @media (width >= 1000px) {
    left: -30px;
    width: 290px;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 3px;
  --vegetarian-mark-inset-inline-start: 50%;
  --vegetarian-mark-width: 16px;
  --vegetarian-mark-transform: translateX(calc(-50% + 90px));

  @media (width >= 650px) {
    --vegetarian-mark-transform: translateX(calc(-50% + 105px));
    --vegetarian-mark-width: 24px;
  }

  @media (width >= 1000px) {
    --vegetarian-mark-inset-inline-start: initial;
    --vegetarian-mark-inset-inline-end: -42px;
    --vegetarian-mark-transform: initial;
  }
}

.kicker-wrapper {
  display: block;
  margin-bottom: 16px;
  margin-inline: auto;
  color: var(--page-theme-contrast-color, var(--color-text));
  text-align: center;

  @media (width >= 650px) {
    margin-bottom: 32px;
    margin-inline: initial;
    text-align: initial;
  }
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
}

.title {
  margin-inline: auto;
  margin-bottom: 55px;
  max-width: 340px;
  color: var(--_theme-color);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-56px);
  line-height: 1.07;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    margin-inline: initial;
    margin-bottom: 45px;
    max-width: none;
    font-size: var(--font-size-72px);
    line-height: 1;
    text-align: initial;
  }

  @media (width >= 1000px) {
    font-size: var(--font-size-96px);
    line-height: 1.06;
  }
}

.subtitle {
  margin-inline: auto;
  margin-bottom: 55px;
  max-width: 340px;
  color: var(--_theme-color);
  font-weight: bold; /* h2 style */
  font-size: var(--font-size-28px);
  font-family: var(--ff-bull); /* h2 style */
  text-align: center;

  @media (width >= 650px) {
    margin-inline: initial;
    margin-bottom: 45px;
    max-width: unset;
    font-size: var(--font-size-32px);
    text-align: initial;
  }
}

.button-wrapper {
  display: none;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: var(--button-wrapper-block-start);

  @media (width >= 650px) {
    display: flex;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

[data-has-custom-theme] .button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
  --cosmos-button-border-color: var(--theming-button-border-color);
}

[data-has-custom-theme] .button[kind="secondary"] {
  --cosmos-button-background: var(--theming-secondary-button-background);
  --cosmos-button-background-hover: var(
    --theming-secondary-button-background-hover
  );
  --cosmos-button-color: var(--theming-secondary-button-color);
  --cosmos-button-color-hover: var(--theming-secondary-button-color-hover);
  --cosmos-button-border-width: var(--theming-secondary-button-border-width);
  --cosmos-button-border-color: var(--theming-secondary-button-border-color);
  --cosmos-button-border-color-hover: var(
    --theming-secondary-button-border-color
  );
}

.video-player {
  background-color: var(--color-bg-shade);
}

.video-shadow-background {
  background-color: var(--color-surface-solid-light-lighter);
  min-height: var(--spacing-bottom);

  @media (width >= 650px) {
    box-shadow: 0 8px 16px 0 rgba(26, 25, 25, 0.08);
    min-height: 10px;
  }
}

.video-wrap {
  position: relative;
  margin-top: 100px;
  margin-bottom: 0;

  @media (width >= 650px) {
    margin-top: 0;
    padding-inline: var(--spacing-sides);
  }
}

.video-wrap-inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.certificate {
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;

  @media (width >= 1000px) {
    bottom: -100px;
    width: 90px;
  }
}

.video-legal-text {
  margin-top: var(--spacing-normal);
  padding-inline: var(--spacing-sides);
  color: var(--color-text-subtle);
  font-size: var(--font-size-12px);
  text-align: center;

  @media (width >= 650px) {
    font-size: var(--font-size-14px);
  }
}
